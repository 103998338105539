<template>
  <div class="reservationList">
    <list queryType="13" @toDetail="toDetail" :listItemConfig="listItemConfig"></list>
    <detailPopup ref="detailPopup"></detailPopup>
  </div>
</template>

<script>
import list from "../list.vue";
import detailPopup from "./detailPopup.vue";
export default {
  name: "reservationList",
  components: { list, detailPopup },
  data() {
    return {
      listItemConfig: {
        imgKey:'photo',
        titleKey: "name",
        layout: [
          {
            label: this.$VUEAPPDEVICECODE(),
            key: "code",
          },
          {
            label: "卡片号",
            key: "label",
          },
          {
            label: "设备分类",
            key: "categoryName",
          },
          {
            label: "品牌",
            key: "brandName",
          },
          {
            label: "型号",
            key: "modelName",
          },
          {
            label: "报障时间",
            key: "endTimeText",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    toDetail(item) {
      this.$refs.detailPopup.show(item.orderId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";
@import "@styles/layout.scss";
.reservationList {
  height: 100%;
}
</style>
